import React from 'react'
import { Link } from "gatsby"

const ProjectList = ({content, posts}) => {
  
  const newLocal = 12;
  const [load, setLoad] = React.useState(newLocal);

  const handleLoad = () => setLoad(load + 3);

return (
  <div className="project-list">
    <div className="container">
      {posts && posts.slice(0, 7).map((caseStudy) => (
        <div className={`row gx-xl-5 mb-135 justify-content-center ${caseStudy.caseStudyDetail.position !== "Center" ? 'align-items-center' : ''} project-card`} key={caseStudy.slug + '-case'}>
          <div className={caseStudy.caseStudyDetail.position === "Center" ? 'col-md-12 mb-md-4 mb-3 pb-xxl-3 pb-lg-2' : caseStudy.caseStudyDetail.position === "Left" ? 'col-sm-7 col-xxl-6 col-12 mb-md-0 mb-3' : 'col-sm-7 col-xxl-6 col-12 mb-md-0 mb-3 order-sm-2'} data-aos='fade-up' data-aos-delay="200">
            {caseStudy.caseStudyDetail.position === "Center" ? (
              <Link aria-label="Case Study" to={'/case-study/'+caseStudy.slug}>
                {/* <img loading="lazy" src={caseStudy.featuredImage && caseStudy.featuredImage.node.mediaItemUrl} alt={caseStudy.featuredImage && caseStudy.featuredImage.node.altText} className="img-fluid w-100 gatsby-image-wrapper" /> */}
                {(() => {
                  if(caseStudy.featuredImage.node?.mediaDetails?.sizes.length > 4){
                    return(
                      <picture>
                        <source media="(min-width:1800px)" srcSet={caseStudy.featuredImage.node?.mediaItemUrl} />
                        <source media="(min-width:1280px)" srcSet={caseStudy.featuredImage.node?.mediaDetails.sizes[4].sourceUrl} />
                        <source media="(min-width:992px)" srcSet={caseStudy.featuredImage.node?.mediaDetails.sizes[1].sourceUrl} />
                        <source media="(min-width:575px)" srcSet={caseStudy.featuredImage.node?.mediaDetails.sizes[3].sourceUrl} />
                        <img loading="lazy" width="1256" height="896" src={caseStudy.featuredImage.node?.mediaDetails.sizes[0].sourceUrl} alt={caseStudy.featuredImage.node?.altText} className="img-fluid w-100 gatsby-image-wrapper" />
                      </picture>
                    )
                  }else{
                    return(
                      <picture>
                        <source media="(min-width:1800px)" srcSet={caseStudy.featuredImage.node?.mediaItemUrl} />
                        <source media="(min-width:992px)" srcSet={caseStudy.featuredImage.node?.mediaDetails.sizes[1].sourceUrl} />
                        <source media="(min-width:575px)" srcSet={caseStudy.featuredImage.node?.mediaDetails.sizes[3].sourceUrl} />
                        <img loading="lazy" width="1256" height="896" src={caseStudy.featuredImage.node?.mediaDetails.sizes[0].sourceUrl} alt={caseStudy.featuredImage.node?.altText} className="img-fluid w-100 gatsby-image-wrapper" />
                      </picture>
                    )
                  }
                })()}
              </Link>
            ) : (
              <Link aria-label='Case Study' to={'/case-study/'+caseStudy.slug}>
                <picture>
                  <source media="(min-width:575px)" srcSet={caseStudy.featuredImage.node?.mediaItemUrl} />
                  <img loading="lazy" width="712" height="417" src={caseStudy.featuredImage.node?.mediaDetails.sizes[0].sourceUrl} alt={caseStudy.featuredImage.node?.altText} className="img-fluid w-100 gatsby-image-wrapper" />
                </picture>
              </Link>
            )}
          </div>

          <div className={caseStudy.caseStudyDetail.position === "Center" ? 'col-10 col-sm-9 col-md-8 col-lg-6 col-xxl-8' : 'col-10 col-xxl-6 col-sm-5'} data-aos='fade-up' data-aos-delay="400">
            <Link aria-label="Case Study" to={'/case-study/'+caseStudy.slug}>
              <h3>{caseStudy.title}</h3>
              <div dangerouslySetInnerHTML={{__html: caseStudy.content}} />
              <p className="mb-lg-1 mb-2"></p>
              <span className="btn text-uppercase btn-link d-inline-flex align-items-center">
                READ THE STORY

                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                </svg>
              </span>
            </Link>
          </div>
        </div>
      ))}

      <div className="py-120 pt-md-5 pt-4">
        <div className="container container-md">            
          <div className="row mb-5 pb-xl-4 justify-content-center text-center" data-aos="fade-up" data-aos-delay="200">
            <div className="col-9 col-sm-7 col-md-6 col-lg-4 col-xl-5 col-xxl-7">
              <div className="section-title">
                <p>{content && content.caseStudy.bottomContentTitle}</p>
              </div>
            </div>
          </div>

          {posts && posts.slice(7, load).map((caseStudy) => (
            <>
              <div className="row py-3 gx-xl-5 justify-content-center align-items-center project-card" key={caseStudy.slug}>
                <div className="col-4 col-md-3 mb-sm-0 mb-2" data-aos="fade-up" data-aos-delay="0.1s">
                  {/* <img loading="lazy" src={caseStudy.caseStudyDetail.smallFeaturedImage && caseStudy.caseStudyDetail.smallFeaturedImage.mediaItemUrl} alt={caseStudy.caseStudyDetail.smallFeaturedImage && caseStudy.caseStudyDetail.smallFeaturedImage.altText} className="img-fluid w-100 gatsby-image-wrapper" /> */}
                  <picture>
                    <source media="(min-width:575px)" srcSet={caseStudy.featuredImage.node?.mediaDetails.sizes[0].sourceUrl} />
                    <img loading="lazy" width="205" height="190" src={caseStudy.featuredImage.node?.mediaDetails.sizes[0].sourceUrl} alt={caseStudy.featuredImage.node?.altText} className="img-fluid w-100 gatsby-image-wrapper" />
                  </picture>
                </div>

                <div className="col-8 col-md-9 mb-sm-0 mb-2" data-aos="fade-up" data-aos-delay="200">
                  <Link aria-label="Case Study" to={'/case-study/'+caseStudy.slug}>
                    <h3>{caseStudy.title}</h3>
                    <div className="d-none d-sm-block">
                      <div dangerouslySetInnerHTML={{__html: caseStudy.content}} />
                      <p className="mb-lg-1 mb-2"></p>
                      <span className="btn text-uppercase btn-link d-inline-flex align-items-center" style={{fontSize: '0.45em'}}>
                        READ THE STORY

                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                        </svg>
                      </span>
                    </div>
                  </Link>
                </div>

                <div className="col-12 wow d-sm-none fadeInUp" data-aos-delay="0.3s">
                  <Link aria-label="Case Study" to={'/case-study/'+caseStudy.slug}>
                    <div dangerouslySetInnerHTML={{__html: caseStudy.content}} />
                    <p className="mb-lg-1 mb-2"></p>
                    <span className="btn text-uppercase btn-link d-inline-flex align-items-center" style={{fontSize: '0.45em'}}>
                      READ THE STORY

                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                      </svg>
                    </span>
                  </Link>
                </div>
              </div>
            
              <hr className="my-lg-5 my-sm-4 my-3" />
            </>
          ))}


          <div className="text-center" data-aos="fade-up">
            <button aria-label="Load more" type='button' onClick={() => handleLoad()} className="btn text-uppercase btn-link d-inline-flex align-items-center">
              Load More ({posts.length - load > 0 ? posts.length - load : 0})

              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi ms-1 bi-arrow-down-short" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"/>
              </svg>
            </button>

            {/* <a href="#" className="btn d-none text-uppercase btn-link d-inline-flex align-items-center">
              Next

              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
              </svg>
            </a> */}
          </div>
        
        </div>
      </div>
    </div>
  </div>
);
}

export default ProjectList;