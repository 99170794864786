import * as React from "react"
import { graphql } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import ProjectList from "../components/projectList"
import Seo from "../components/seo"

const CaseSudyPage = ({data}) => {
  const pageContent = data.allWpPage.nodes[0];
  return(
    <Layout>
      <Seo title={pageContent?.seo.title} image="https://dev.alkye.com/wp-content/uploads/2021/12/Sonia-1-1-scaled-1.webp" description={pageContent?.seo.metaDesc} focuskw={pageContent?.seo.focuskw} /> 
      
      <div className="page-intro page-inner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-xl-9 col-xxl-11">
              <h1 className="title" dangerouslySetInnerHTML={{__html: pageContent?.caseStudy.title}} />
  
              <div className="row">
                <div className="col-10 col-sm-11 col-md-9" dangerouslySetInnerHTML={{__html: pageContent?.content}} />
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <ProjectList content={pageContent} posts={pageContent?.caseStudy.caseStudiesProjects} />
    
    </Layout>
  );
}

export default CaseSudyPage

export const query = graphql`{
  allWpPage(filter: {slug: {eq: "case-study"}}) {
    nodes {
      seo {
        title
        metaDesc
        focuskw
      }
      caseStudy {
        title
        relatedWorksTitle
        bottomContentTitle
        caseStudiesProjects {
          ... on WpCaseStudy {
            content
            featuredImage {
              node {
                altText
                mediaItemUrl
                mediaDetails {
                  sizes {
                    sourceUrl
                  }
                }
              }
            }
            slug
            title
            caseStudyDetail {
              position
            }
          }
        }
      }
      content
    }
  }
  allWpCaseStudy {
    nodes {
      content
      featuredImage {
        node {
          altText
          mediaItemUrl
        }
      }
      slug
      title
      caseStudyDetail {
        smallFeaturedImage {
          altText
          mediaItemUrl
        }
        position
      }
    }
  }
}`